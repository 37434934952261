<template>
    <v-dialog
        :value="value"
        scrollable
        width="700"
        @input="onInput(false)"
        @keydown.esc="onInput(false)"
        @click:outside="onInput(false)"
    >
        <questions-list
            v-model="selectedItems"
            height="700"
            :payload="payload"
            hide-author
            hide-size
            selectable
            searchable
            :exclude-select="item => item.type === 'folder'"
        >
            <template #footer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="onCancel"
                >
                    {{ $trans('Cancel') }}
                </v-btn>
                <v-btn
                    :disabled="selectedItems.length === 0"
                    color="primary"
                    text
                    @click="onSelect"
                >
                    {{ $trans('Add') }}
                </v-btn>
            </template>
        </questions-list>
    </v-dialog>
</template>

<script>
export default {
    name: 'QuestionsPicker',
    components: {
        QuestionsList: () => import('@apps/questions/components/QuestionsList')
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Select'
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            mediaTableKey: 0,
            selectedItems: []
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },
        onCancel() {
            this.selectedItems = []
            this.$emit('input', false)
        },
        onSelect() {
            this.$emit('select', [].concat(this.selectedItems))
            this.selectedItems = []
            this.$emit('input', false)
        }
    }
}
</script>

<style lang=scss>

</style>
